import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '@context';
import { useApolloClient } from '@apollo/client';
import { completeMissionMutation, getUserMissions } from '@apollo';
import { MissionsInfo } from '@data';
import { nextMissionModalInfo } from '@utils';
import { navigate, PageProps } from 'gatsby';
import { useMediaQuery } from '@material-ui/core';
import { SEO } from '@components';
import { LayoutMissionDesktop, LayoutMissionMobile } from '@components';
import { useAuth } from '@hooks';
import CreditSimulator from '@components/Simulator/CreditSimulator';
import CreditSimulator2 from '@components/Simulator/CreditSimulator2';
import { Route } from '@interfaces';

const LoansDebtIndex: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });
  const isMobile = useMediaQuery('(min-width: 1240px)');
  const [page, setPage] = React.useState(1);
  const { userMissions } = getUserMissions(); //!! to update mission
  const client = useApolloClient(); //!! to update mission
  const [confettiFire, setConfettiFire] = useState<boolean>(false);
  const { openCongratsModal } = useContext(AppContext);
  const adjustDesktop = {
    paddingLeft: '1rem',
  };
  const adjustMobile = {
    marginTop: '4rem',
  };
  const [monthPayment, setMonthPayment] = useState(0);
  const [term, setTerm] = useState(10);
  const [rate] = useState(0.03);

  const categoryMissions = userMissions.filter(
    (obj) => obj.mission.category == MissionsInfo.LoansDebtCategory.name
  );
  const currentMission = categoryMissions.filter(
    (obj) => obj.mission.id == MissionsInfo.LoansDebtCategory.m2_2.id
  )[0];

  const handleClick = () => {
    // if (currentMission?.state == "PENDING") {
    try {
      completeMissionMutation(client, currentMission.mission.id);
      setConfettiFire(true);
      openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    } catch (error) {
      console.log(error);
    }
    // } else {
    //   setConfettiFire(true);
    //   openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    // }
  };

  useEffect(() => {
    // if (currentMission?.state == "COMPLETED") {
    //   setConfettiFire(true);
    //   openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    // } else {
    //   setConfettiFire(false);
    // }
    setConfettiFire(false);
  }, [currentMission]);

  return (
    <>
      <SEO />
      {page === 1 ? (
        <>
          {isMobile ? (
            <LayoutMissionDesktop
              title={'Simulador básico de crédito'}
              number={2}
              confettiFire={confettiFire}
              onHouseClick={() => navigate(Route.creditsDebts)}
            >
              <CreditSimulator
                setPage={setPage}
                adjustDesktop={adjustDesktop}
                setMonthPayment={setMonthPayment}
                term={term}
                setTerm={setTerm}
                rate={rate}
              />
            </LayoutMissionDesktop>
          ) : (
            <LayoutMissionMobile
              title={'Simulador básico de crédito'}
              number={2}
              onHouseClick={() => navigate(Route.creditsDebts)}
              confettiFire={confettiFire}
            >
              <CreditSimulator
                setPage={setPage}
                adjustDesktop={adjustMobile}
                setMonthPayment={setMonthPayment}
                term={term}
                setTerm={setTerm}
                rate={rate}
              />
            </LayoutMissionMobile>
          )}
        </>
      ) : (
        <>
          {isMobile ? (
            <LayoutMissionDesktop
              title={'Simulador básico de crédito'}
              number={2}
              secondLayoutForShortcuts
              setPage={setPage}
              pageNumber={1}
              confettiFire={confettiFire}
              onHouseClick={() => navigate(Route.creditsDebts)}
            >
              <CreditSimulator2
                setPage={setPage}
                adjustDesktop={adjustDesktop}
                isMission
                monthPayment={monthPayment}
                term={term}
                rate={rate}
                handleClick={handleClick}
              />
            </LayoutMissionDesktop>
          ) : (
            <LayoutMissionMobile
              title={'Simulador básico de crédito'}
              number={2}
              onHouseClick={() => navigate(Route.creditsDebts)}
              confettiFire={confettiFire}
            >
              <CreditSimulator2
                setPage={setPage}
                adjustDesktop={adjustMobile}
                isMission
                monthPayment={monthPayment}
                term={term}
                rate={rate}
                handleClick={handleClick}
              />
            </LayoutMissionMobile>
          )}
        </>
      )}
    </>
  );
};

export default LoansDebtIndex;
